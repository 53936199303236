// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Footer from './Common/Footer';
// import Header from './Common/Header'
// import Home from './Landing';
// import Paint from './Paint';
// import PlyWood from './PlyWood'
// import HardWare from './HardWare'
// import ScrollToTop from './Common/ScrollTop';
// import Courser from './Courser';
// function App() {
//   return (
//     <Router>
//       <Header/>
//       <Courser/>
//       <ScrollToTop/>
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/paint" element={<Paint />} />
//         <Route path="/plywood" element={<PlyWood />} />
//         <Route path="/hardware" element={<HardWare />} />
//       </Routes>

//       <Footer/>
//     </Router>

//   );
// }

// export default App;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./Common/Footer";
import Header from "./Common/Header";
import Home from "./Landing";
import Paint from "./Paint";
import PlyWood from "./PlyWood";
import HardWare from "./HardWare";
import ScrollToTop from "./Common/ScrollTop";
import Courser from "./Courser";
import FloatingWhatsAppIcon from "./FloatingWhatsAppIcon";
import Contact from "./Common/Contact";
import Gallery from "./Gallery/Gallery.js";
import "./App.css";

function App() {
  return (
    <div class="app">
      <Router>
        <Header />
        <Courser />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/paint" element={<Paint />} />
          <Route path="/plywood" element={<PlyWood />} />
          <Route path="/hardware" element={<HardWare />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
        <Footer />
        <FloatingWhatsAppIcon />
      </Router>
    </div>
  );
}

export default App;
