import React from 'react'
import Banner from './Banner';
import Brands from './Brands';
import ProductRange from './ProductRange';
import WaterProof from './WaterProof';
import WoodFinishesSection from './WoodFinish';
import Exterior from './Exterior';
import Interior from './Interior';
import Advice from './Advice'
import OnTimeServices from './OnTime';
import Projects from './projects';
import ContactSection1 from './ContactSection1';
const index = () => {
  return (
    <div>
      <Banner/>
<Brands/>
<ProductRange/>
<WaterProof/>
<WoodFinishesSection/>
<Exterior/>
<Interior/>
<Advice/>
<OnTimeServices/>
<Projects/> 
<ContactSection1/> 
    </div>
  )
}

export default index
