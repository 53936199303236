import React from 'react';
import './Styles/CommitmentSection.css';
import Commit1 from './Images/commit1.png'
import Commit2 from './Images/commit2.png'
import Commit3 from './Images/commit3.png'
import Commit4 from './Images/commit4.png'
const CommitmentItem = ({ image, title, description }) => {
  return (
    <div className="commitment-item ">
      <img src={image} alt={title} className="commitment-item__image" />
      <h3 className="commitment-item__title">{title}</h3>
      <p className="commitment-item__description">{description}</p>
    </div>
  );
};

const CommitmentSection = () => {
  const commitments = [
    { image: Commit1, title: 'Superior Quality', description: 'Every product undergoes rigorous quality checks to ensure reliability and performance.' },
    { image: Commit2, title: 'Competitive Pricing', description: 'We balance high quality with affordable prices, offering great value for your investment.' },
    { image: Commit3, title: 'Peace of Mind', description: 'Our commitment to quality ensures that you can trust our products to meet your needs.' },
    { image: Commit4, title: 'Trusted Choice', description: 'We are a preferred choice for hardware needs due to our focus on quality and affordability.' },
  ];

  return (
    <div className="commitment">
      <h2 className="commitment__title" data-aos="fade-up" data-aos-duration="1500">
        Commitment to <span className="commitment__highlight">Quality and Affordability</span>
      </h2>
      <div className="commitment__grid" data-aos="zoom-in" data-aos-duration="1500" >
        {commitments.map((commitment, index) => (
          <CommitmentItem
            key={index}
            image={commitment.image}
            title={commitment.title}
            description={commitment.description}
          />
        ))}
      </div>
    </div>
  );
};

export default CommitmentSection;
