import React from 'react';
import './Styles/PlywoodGallery.css';
import c1 from './Images/c1.png';
import Image1 from './Images/New/01.jpg'
import Image2 from './Images/New/3.jpeg'
import Image3 from './Images/New/04.jpg'
import Image4 from './Images/New/05.webp'

import Image5 from './Images/New/09.webp'



import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Default for large screens
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false, // Hides the arrows if you prefer to use dots only
    responsive: [
      {
        breakpoint: 1200, // Large devices
        settings: {
          slidesToShow: 4, // Show 4 slides for screens >= 1200px
        },
      },
      {
        breakpoint: 992, // Medium devices (tablets, smaller desktops)
        settings: {
          slidesToShow: 3, // Show 3 slides for screens >= 992px
        },
      },
      {
        breakpoint: 768, // Small devices (tablets)
        settings: {
          slidesToShow: 2, // Show 2 slides for screens >= 768px
        },
      },
      {
        breakpoint: 480, // Extra small devices (mobiles)
        settings: {
          slidesToShow: 1, // Show 1 slide for screens < 480px
        },
      },
    ],
  };

  return (
    <div className="image-slider">
      <h2 className="image-slider__title">
        <span className="image-slider__title--main" data-aos="fade-up" data-aos-duration="1500">Complete </span>
        <span className="image-slider__title--highlight" data-aos="fade-up" data-aos-duration="1500">Interior Solutions</span>
      </h2>
      <h3 className="image-slider__subtitle" data-aos="fade-up" data-aos-duration="1500">Wide Selection</h3>
      <p className="image-slider__description" data-aos="fade-up" data-aos-duration="1500">
        We understand that every detail matters in interior design. That's why we provide a complete range of accessories essential for your projects. Whether you're renovating a kitchen, upgrading a living room, or designing a commercial space, our diverse inventory ensures you find everything you need to bring your vision to life.
      </p>
      <Slider {...settings}>
        <div className="gallery-carousel__image">
          <img src={Image1} alt="Interior 1" />
        </div>
        <div className="gallery-carousel__image">
          <img src={Image2} alt="Interior 2" />
        </div>
        <div className="gallery-carousel__image">
          <img src={Image3} alt="Interior 3" />
        </div>
        <div className="gallery-carousel__image">
          <img src={Image4} alt="Interior 4" />
        </div>
        <div className="gallery-carousel__image">
          <img src={Image5} alt="Interior 5" />
        </div>
      </Slider>
      {/* <div className="image-slider__carousel" data-aos="zoom-in" data-aos-duration="1500">
        <button className="image-slider__carousel-control image-slider__carousel-control--prev">❮</button>
        <div className="image-slider__carousel-images">
          <div className="image-slider__image">
            <img src={c1} alt="Interior Solution 1" />
          </div>
          <div className="image-slider__image">
            <img src={c1} alt="Interior Solution 2" />
          </div>
          <div className="image-slider__image">
            <img src={c1} alt="Interior Solution 3" />
          </div>
          <div className="image-slider__image">
            <img src={c1} alt="Interior Solution 4" />
          </div>
          <div className="image-slider__image">
            <img src={c1} alt="Interior Solution 3" />
          </div>
          <div className="image-slider__image">
            <img src={c1} alt="Interior Solution 4" />
          </div><div className="image-slider__image">
            <img src={c1} alt="Interior Solution 3" />
          </div>
          <div className="image-slider__image">
            <img src={c1} alt="Interior Solution 4" />
          </div>
        </div>
        <button className="image-slider__carousel-control image-slider__carousel-control--next">❯</button>
      </div> */}
    </div>
  );
};

export default ImageSlider;