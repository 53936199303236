import React from 'react';
import './Styles/BackgroundSection.css';


const BackgroundSection = () => {
  return (
    <div className="background-section">
      <div className="background-section__overlay"></div>
    </div>
  );
}

export default BackgroundSection;