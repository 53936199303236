// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Replace these with your Firebase project configuration details
const firebaseConfig = {
  apiKey: "AIzaSyArJSRcNG5UMcKS0Swq65LHcE6CBsaetkA",
  authDomain: "paint-97850.firebaseapp.com",
  projectId: "paint-97850",
  storageBucket: "paint-97850.appspot.com",
  messagingSenderId: "981556023295",
  appId: "1:981556023295:web:6f00d37ddf253f9d8321ca",
  measurementId: "G-B0M79FFTK7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

export { db, storage };
