import React from 'react'
import HeroSection from './HeroSection';
import ProductsSection from './ProductsSection';
import PlywoodGallery from './PlywoodGallery'
import AdviceSupportSection from './AdviceSupport';
import BackgroundSection from './BackgroundSection';
import Brands from './Brands';

const index = () => {
  return (
    <div>
      <HeroSection/>
   <Brands/>
   <PlywoodGallery/>
   <AdviceSupportSection/>
   <BackgroundSection/>
   <ProductsSection/> 
    </div>
  )
}

export default index
