// import React from 'react';
// import './Styles/ContactUs.css';

// const ContactSection = () => {
//   return (
//     <div className="contact  pt-5">
//       <div className="contact__content">
//         <h2 className="contact__title" data-aos="zoom-in" data-aos-duration="1500">Let's get in touch</h2>
//         <p className="contact__subtitle" data-aos="zoom-in" data-aos-duration="1500">Reach out to us for product inquiries, or any assistance you need</p>
//         <button className="contact__button">
//           Contact Us <span className="contact__button-icon">↗</span>
//         </button>
//       </div>

//     </div>
//   );
// }

// export default ContactSection;

import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Styles/ContactUs.css";

const ContactSection = () => {
  const navigate = useNavigate(); // Hook to get the navigate function

  const handleContactClick = () => {
    navigate("/contact-us"); // Navigate to the /contact-us route
  };

  return (
    <div className="contact pt-5">
      <div className="contact__content">
        <h2
          className="contact__title"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          Let's get in touch
        </h2>
        <p
          className="contact__subtitle"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          Reach out to us for product inquiries, or any assistance you need
        </p>
        <button className="contact__button" onClick={handleContactClick}>
          Contact Us <span className="contact__button-icon">↗</span>
        </button>
      </div>
    </div>
  );
};

export default ContactSection;
