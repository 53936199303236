// import React from 'react';
// import './Styles/Contact.css';
// import circle from './Images/circle-01.png.png'
// import btn2 from './Images/ethereum-02.png.png'
// const Contact = () => {
//   return (
//     <div className="contact-us">
//       <img src={circle} alt="" className="circle" />
//       <img src={btn2} alt="" className="btn2" />
//       <div className="contact-us__content">
//         <h2 className="contact-us__title" data-aos="fade-down" data-aos-duration="2000">Let's get in <span className="faq__highlight gradient cnt-txt">touch</span></h2>
//         <p className="contact-us__subtitle" data-aos="fade-down" data-aos-duration="2000">Reach out to us for expert advice, product inquiries, or any assistance you need</p>
//         <button className="contact-us__button">
//           Contact Us <span className="contact-us__button-icon">↗</span>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Contact;

import React from "react";
import { useNavigate } from "react-router-dom";
import "./Styles/Contact.css";
import circle from "./Images/circle-01.png.png";
import btn2 from "./Images/ethereum-02.png.png";

const Contact = () => {
  const navigate = useNavigate(); // Hook to get the navigate function

  const handleContactClick = () => {
    navigate("/contact-us"); // Navigate to the /contact-us route
  };

  return (
    <div className="contact-us">
      <img src={circle} alt="" className="circle" />
      <img src={btn2} alt="" className="btn2" />
      <div className="contact-us__content">
        <h2
          className="contact-us__title"
          data-aos="fade-down"
          data-aos-duration="2000"
        >
          Let's get in{" "}
          <span className="faq__highlight gradient cnt-txt">touch</span>
        </h2>
        <p
          className="contact-us__subtitle"
          data-aos="fade-down"
          data-aos-duration="2000"
        >
          Reach out to us for expert advice, product inquiries, or any
          assistance you need
        </p>
        <button className="contact-us__button" onClick={handleContactClick}>
          Contact Us <span className="contact-us__button-icon">↗</span>
        </button>
      </div>
    </div>
  );
};

export default Contact;
