import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./FloatingWhatsAppIcon.css";

function FloatingWhatsAppIcon() {
  const handleClick = () => {
    window.open("https://wa.me/+919677620916", "_blank"); // Replace '1234567890' with your WhatsApp number
  };

  return (
    <div className="floating-whatsapp-icon" onClick={handleClick}>
      <FontAwesomeIcon icon={faWhatsapp} size="2x" />
    </div>
  );
}

export default FloatingWhatsAppIcon;
