import React from "react";
import "./Styles/ProductRange.css";
import ProductRange1 from "./Images/productRange.png";
import drop1 from "./Images/drop_1.png";
import drop2 from "./Images/drop_2.png";
const ProductRange = () => {
  return (
    <div className="Product_range_container">
      <h2 className="product-range__title" data-aos="fade-up" data-aos-duration="1500">
        Our{" "}
        <span className="product-range__title--highlight">Product Range</span>
      </h2>
      <div className=" product-range">
        <img src={drop1} alt="" className="drop1" />
        <img src={drop2} alt="" className="drop2" />

  
          <div className="product-range__content" data-aos="fade-up"  data-aos-duration="1500">
            <div className="product-range__item product-range__item--left">
              <h3 className="product-range__item-number">01.</h3>
              <h4 className="product-range__item-title">
                Classic and Modern Styles
              </h4>
              <p className="product-range__item-description">
                We offer both timeless designs and the latest trends to suit any
                taste.
              </p>
              <div className="third_line">
                <h3 className="product-range__item-number">03.</h3>
                <h4 className="product-range__item-title">For Any Project</h4>
                <p className="product-range__item-description">
                  Whether it's a small update or a full renovation, we have you
                  covered.
                </p>
              </div>
            </div>
            <div className="product-range__image">
              <img src={ProductRange1} alt="Paint Brushes" data-aos="zoom-in" data-aos-duration="2000"/>
            </div>  
            <div className="product-range__item product-range__item--right">
              <div className="Second_2">
                <h3 className="product-range__item-number">02.</h3>
                <h4 className="product-range__item-title">Wide Selection</h4>
                <p className="product-range__item-description">
                  Choose from wallpapers, wall designs, and paints that fit
                  every need.
                </p>
              </div>
              <div className="Second_4">
                <h3 className="product-range__item-number mt-5">04.</h3>
                <h4 className="product-range__item-title">Perfect Match</h4>
                <p className="product-range__item-description">
                  We help you find the perfect match for your vision, guiding
                  you through our extensive selection to bring your ideas to
                  life.
                </p>
              </div>
            </div>

         
          </div>
  
      </div>
    </div>
  );
};

export default ProductRange;
