import React from "react";
import "./Styles/Services.css";
import s1 from "./Images/service (1).png";
import s2 from "./Images/service (2).png";
import s3 from "./Images/service (3).png";
import ball from "./Images/Ellipse 1.png";
import ball2 from "./Images/Ellipse 2.png";
import ballr from "./Images/Ellipse 4.png";
import ballrb from "./Images/Ellipse 3.png";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  const GoPaint = () => {
    navigate("/paint");
  };
  const goPlywood = () => {
    navigate("/plywood");
  };
  const goHardware = () => {
    navigate("/hardware");
  };
  return (
    <div className="services">
      <h2
        className="services__title"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        Our <span className="gradient">services</span>
      </h2>
      <div className="services__list">
        <img src={ball} alt="" className="ball-abt" />
        <img src={ball2} alt="" className="ball-abt2" />
        <img src={ball} alt="" className="ball-abtr" />
        <img src={ball} alt="" className="ball-abtrb" />
        <div className="services__item mt-20" onClick={GoPaint}>
          <img
            src={s2}
            alt="Premium Paints"
            className="services__image"
            data-aos="fade-left"
            data-aos-duration="1500"
          />
          <div
            className="services__content"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <p className="gradient-number">01.</p>
            <h3 className="services__heading">Thagappanadar Paints</h3>
            <h3 className="services__sub_heading">
              Transform Your Space with Color
            </h3>
            <p className="services__description">
              At Thagappanadar Paints, we bring over 20 years of expertise,
              offering a wide range of products to suit all budgets.
            </p>
            <p className="services__description">
              Managed by Senthil Kumaran Anandrajan and Saravanakumar, we offer
              more than products — our services are crafted to elevate every
              step of your painting experience.
            </p>
            <p className="link_service">
              <a href="#" className="services__link">
                Learn more <i class="bi bi-arrow-up-right"></i>
              </a>
            </p>
          </div>
        </div>

        <div className="services__item" onClick={goPlywood}>
          <div
            className="services__content"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <p className="gradient-number">02</p>
            <h3 className="services__heading">Thagappanadar Plywood</h3>
            <h3 className="services__sub_heading">
              Strength and Style in Every Sheet
            </h3>
            <p className="services__description">
              Welcome to Thangappanadar Plywoods, established in 2019 as a
              trusted source for premium plywood and interior essentials.
            </p>
            <p className="services__description">
              A customer-focused plywood shop managed by Yasmin, dedicated to
              meeting all your woodworking needs with a personal touch.
            </p>
            <p className="link_service">
              <a href="#" className="services__link">
                Learn more <i class="bi bi-arrow-up-right"></i>
              </a>
            </p>
          </div>
          <img
            src={s3}
            alt="Superior Plywood"
            className="services__image"
            data-aos="fade-left"
            data-aos-duration="1500"
          />
        </div>

        <div className="services__item" onClick={goHardware}>
          <img
            src={s1}
            alt="Quality Hardware"
            className="services__image"
            data-aos="fade-left"
            data-aos-duration="1500"
          />
          <div
            className="services__content"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <p className="gradient-number">03</p>
            <h3 className="services__heading">Thagappanadar Hardware</h3>
            <h3 className="services__sub_heading">
              Durability Meets Functionality
            </h3>
            <p className="services__description">
              At Thangappanadar Hardware, established in 1919 and expanded in
              2008, we offer a wide selection of products, from essential bolts
              and nuts to sturdy door locks and ladders.
            </p>
            <p className="services__description">
              Managed by Navaneetha Krishnan and Hari Ram, our commitment to
              quality meets all your hardware needs under one roof.
            </p>
            <p className="link_service">
              <a href="#" className="services__link">
                Learn more <i class="bi bi-arrow-up-right"></i>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
