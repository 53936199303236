import React from 'react';
import './Styles/ProductsSection.css';
import pro from './Images/pro.png'
const ProductsSection = () => {
  return (
    <div className='play_wood_productsec_conatianer'> 
       <h2 className="productsec__title" data-aos="fade-up" data-aos-duration="1500">Our <span className="products__highlight">Products</span></h2>
    <div className="products_sec">
      <div className="products__image" data-aos="fade-left" data-aos-duration="1500">
        <img src={pro} alt="Office desk illustration" className="products__image-content" />
      </div>
      <div className="products__content">
     
      <h3 className="productsec__subtitle" data-aos="fade-right" data-aos-duration="1500">Tailored Solutions for Every Need</h3>
        <ul className="products__list" data-aos="right" data-aos-duration="1500">
          <li className="products__item">
            
            <strong>Versatile Plywood Options:</strong> Our plywood section offers a wide range of choices to meet various needs.
          </li>
          <li className="products__item">
            <strong>Plywood and MDF Sheets:</strong> Select from standard and waterproof MDF sheets, perfect for diverse interior applications.
          </li>
          <li className="products__item">
            <strong>Durable Merbok Shutter Boards:</strong> Ideal for tank covers, providing strength and reliability.
          </li>
          <li className="products__item">
            <strong>Robust Diamond Sheets:</strong> Designed for heavy-duty use, perfect for vehicle floors and industrial platforms.
          </li>
          <li className="products__item">
            <strong>Quality and Durability:</strong> We provide solutions that are built to exceed expectations in both performance and longevity.
          </li>
        </ul>
      </div>
    </div>
    </div>
  );
}

export default ProductsSection;