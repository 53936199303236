import React from "react";
import "./Styles/Advice.css";
import im from "./Images/character-analyzing.png";
import balls1 from "./Images/Ellipse 5.png";
import balls2 from "./Images/Ellipse 7.png";
import balls3 from "./Images/Ellipse 6.png";
const PaintConsultations = () => {
  return (
    <div className="paint-consultations">
      <div className="paint-consultations__header">
        <h1 className="paint-consultations__title" data-aos="fade-up">
          Expert{" "}
          <span className="paint-consultations__title--highlight">Advice</span>
        </h1>
        <img src={balls1} alt="" className="balls1" />
        <img src={balls2} alt="" className="balls2" />
        <img src={balls3} alt="" className="balls3" />
      </div>
      <div className="paint-consultations__content">
        <div
          className="paint-consultations__text"
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          <h2 className="paint-consultations__subtitle">Paint Consultations</h2>
          <p className="paint-consultations__tagline">
            Strength and Style in Every Sheet
          </p>
          <p className="paint-consultations__description">
            Renowned as colour experts, we go beyond mere products to offer
            specialized colour services. Our team provides personalized colour
            consulting, offering expert suggestions tailored to your preferences
            and project requirements.
          </p>
          <p className="paint-consultations__description">
            With advanced colour visualization tools, we bring your ideas to
            life,
          </p>
          <p className="paint-consultations__description">
            Rendering images with your chosen colours to help you visualize the
            final result before a single brushstroke is made.
          </p>
        </div>
        <div
          className="paint-consultations__image"
          data-aos="fade-left"
          data-aos-duration="1500"
        >
          <img
            src={im}
            alt="Paint Consultation"
            className="paint-consultations__image-file"
          />
        </div>
      </div>
    </div>
  );
};

export default PaintConsultations;
