import React, { useState } from "react";
import "./Styles/Faq.css";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`faq__item ${isOpen ? "faq__item--open" : ""}`}
    //   data-aos="zoom-in"
    //   data-aos-duration="2000"
    >
      <div className="faq__question" onClick={() => setIsOpen(!isOpen)}>
        {question}
        <span className={`faq__icon ${isOpen ? "faq__icon--open" : ""}`}>
          {isOpen ? <UpOutlined /> : <DownOutlined />}
        </span>
      </div>
      {isOpen && <div className="faq__answer">{answer}</div>}
    </div>
  );
};

const Faq = () => {
  const faqs = [
    {
      question: "What types of products does Thagappanadar offer?",
      answer:
        "We specialize in paints, hardware, and plywood, catering to various construction and home improvement needs.",
    },
    {
      question: "How long has Thagappanadar been in business?",
      answer:
        "Thagappanadar has been serving customers with quality products for over a century.",
    },
    {
      question: "Do you offer guidance on choosing the right materials?",
      answer:
        "Yes, our experienced staff can help you select the best products for your specific project requirements.",
    },
    {
      question: "Can I place bulk orders?",
      answer:
        "Absolutely. We accommodate both individual and bulk orders and offer competitive rates for larger quantities.",
    },
    {
      question: "Where is Thagappanadar located?",
      answer:
        "Our shop location details are available on our contact page. Feel free to visit us for personalized assistance.",
    },
  ];

  return (
    <div className="faq" data-aos="" data-aos-duration="100">
      <h2 className="faq__title">
        Your questions, <br></br>
        <span className="faq__highlight gradient">answered!</span>
      </h2>
      <div className="faq__list">
        {faqs.map((faq, index) => (
          <FaqItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default Faq;
