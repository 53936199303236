import React from "react";
import Brands from "./Brands";
import CommitmentSection from "./CommitmentSection";
import ContactSection from "./ContactUs";
import InteriorSolutions from "./InteriorSolutions";
import Banner from "./Banner";
const index = () => {
  return (
    <div>
      <Banner />
      <Brands />
      <InteriorSolutions />
      <CommitmentSection />
      <ContactSection />
    </div>
  );
};

export default index;
