import React from "react";
import "./Styles/AboutUs.css";
import AboutUsImg from "./Images/AboutUs.png";
import ball1 from "./Images/Ellipse 5.png";
import ball2 from "./Images/Ellipse 6.png";
const AboutUs = () => {
  return (
    <section className="about-us">
      <img src={ball1} alt="" className="ball1" />
      <img src={ball2} alt="" className="ball2" />
      <div className="about-us__container">
        <div className="about-us__image-container">
          <img
            src={AboutUsImg}
            alt="Team Working"
            className="about-us__image"
            data-aos="fade-left"
            data-aos-duration="1500"
          />
        </div>
        <div
          className="about-us__content"
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          <h1 className="about-us__title">About Us</h1>
          <h2 className="about-us__subtitle">
            Thagappanadar Paints, Hardware & Plywoods <br />– Since 1919.
          </h2>
          <p className="about-us__description">
            Established in 1919, Thagappanadar started as a wood and hardware
            shop and grew into a trusted provider of paints and plywoods. In
            1998, we became dealers of Asian Paints, and in 2019, expanded our
            range to premium plywoods.
          </p>
          <p className="about-us__description">
            With a century of commitment to quality, we offer expert guidance
            and reliable products to meet all your project needs.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
