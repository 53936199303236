import React from "react";
import "./Styles/Footer.css";
import logo from "./Images/logo.png";
const Footer = () => {
  return (
    <footer className="footer pb-0">
      <div className="footer__content container">
        <div className="footer__brand">
          <img src={logo} alt="Brand Logo" className="footer__logo" />
          <p className="footer__description">
            <span>Email: <a href="mailto:thangappanadar@gmail.com">thangappanadar@gmail.com</a></span><br />
            <span>Telephone: <a href="tel:+614563260916">04563 260 916</a></span><br />
            <span>WhatsApp: <a href="https://wa.me/9677620916" target="_blank" rel="noopener noreferrer">9677620916</a></span><br />
            <span>Paint shop location: <a href="https://maps.app.goo.gl/BrpJ7qLvGvWw4iRN6" target="_blank" rel="noopener noreferrer">View on Google Maps</a></span><br />
            <span>Plywood shop location: <a href="https://maps.app.goo.gl/xzxkmNMdnKxCRfQM8" target="_blank" rel="noopener noreferrer">View on Google Maps</a></span>
          </p>


          <div className="footer__social">
         
            <a href="https://www.instagram.com/thangappanadar_official/?igsh=dXd0emhjM2xnNGR5" className="footer__social-link">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="https://www.youtube.com/@thangappathangappa3772" className="footer__social-link">
              <i className="bi bi-youtube"></i>
            </a>
          </div>
        </div>
        <div className="footer__links">
          <div className="footer__column">
            <h3 className="footer__heading">Quick Link</h3>
            <ul className="footer__list">
              <li>
                <a href="#" className="footer__link">
                  Home
                </a>
              </li>
              <li>
                <a href="#" className="footer__link">
                  About us
                </a>
              </li>
              <li>
                <a href="#" className="footer__link">
                  Contact us
                </a>
              </li>
              <li>
                <a href="#" className="footer__link">
                  FAQs
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__column">
            <h3 className="footer__heading">Services</h3>
            <ul className="footer__list">
              <li>
                <a href="#" className="footer__link">
                  Paint
                </a>
              </li>
              <li>
                <a href="#" className="footer__link">
                  Plywood
                </a>
              </li>
              <li>
                <a href="#" className="footer__link">
                  Hardware
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer__bottom_border border-top">
        <div className="container  pt-1 pb-1">
          <div className="footer__bottom ">
            <p className="footer__copyright ">
              Copyright © 2024 Thagappanadar. All Rights Reserved.
            </p>
            <div className="footer__policies">
              <a href="#" className="footer__policy-link">
                Privacy policy
              </a>
              <a href="#" className="footer__policy-link">
                Terms of Use
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
