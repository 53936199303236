// import React from "react";
// // import bg from '../Common/Images/bg-1'
// import house from "./Images/house.png";
// import "./Styles/Banner.css";
// const Banner = () => {
//   return (
//     <div className="banner-container  ">
//       <section className="hero-section ">
//         <div className="hero-section__content" data-aos="fade-right" data-aos-duration="1500">
//           <h1 className="hero-section__title ">Welcome to Thagappanadar!</h1>
//           <p className="hero-section__subtitle">
//             {" "}
//             Over a Century of Excellence in Paints, Hardware, and Plywoods{" "}
//           </p>
//         </div>
//         <div className="hero-section__image" data-aos="fade-left" data-aos-duration="1500">
//           {" "}
//           <img
//             src={house}
//             alt="House Model"
//             className="hero-section__image-img"
//           />{" "}
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Banner;

import React from "react";
// import house from "./Images/house.png";
import Image1 from "./Images/New/1.png"
import "./Styles/Banner.css";
const Banner = () => {
  return (
    <div className="banner-container  ">
      <section className="hero-section ">
        <div className="hero-section__content" data-aos="fade-right" data-aos-duration="1500">
          <h1 className="hero-section__title ">Welcome to Thagappanadar!</h1>
          <p className="hero-section__subtitle">
            {" "}
            Over a Century of Excellence in Paints, Hardware, and Plywoods{" "}
          </p>
        </div>
        <div className="hero-section__image" data-aos="fade-left" data-aos-duration="1500">
          {" "}
          <img
            src={Image1}
            alt="House Model"
            className="hero-section__image-img"
          />{" "}
        </div>
      </section>
    </div>
  );
};

export default Banner;




