import React from "react";
import "./Styles/Banner.css";
const Banner = () => {
  return (
    <div className="hero">
      <h1 className="hero__title" data-aos="zoom-in" data-aos-duration="1500">
        Welcome to <br></br>
        <span className="hero__title--highlight">Thagappanadar Paints</span>
      </h1>
      {/* <p
        className="hero__description"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        We are industry leaders with over{" "}
        <strong>25+ years of experience</strong>, providing a wide range of
        products tailored to meet every need, from economical choices to{" "}
        <strong>luxury options</strong>.
      </p> */}
    </div>
  );
};

export default Banner;
