

// import Button from "react-bootstrap/Button";
// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import logo from "./Images/logo.png";
// import "./Styles/Header.css";
// import { useNavigate, useLocation } from "react-router-dom";

// function NavScrollExample() {
//   const navigate = useNavigate();
//   const location = useLocation();

//   const isActive = (path) => location.pathname === path;

//   return (
//     <Navbar expand="lg" className="custom-navbar" sticky="top">
//       <Container fluid>
//         <Navbar.Brand onClick={() => navigate("/")}>
//           <img src={logo} className="navbar__logo-image" alt="logo" />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="navbarScroll" />
//         <Navbar.Collapse id="navbarScroll" className="justify-content-end" >
//           <Nav className="my-2 my-lg-0" navbarScroll>
//             <Nav.Link
//               onClick={() => navigate("/")}
//               className={`navbar__link ${
//                 isActive("/") ? "navbar__link--active" : ""
//               }`}
//             >
//               Home
//             </Nav.Link>
//             <Nav.Link
//               onClick={() => navigate("/paint")}
//               className={`navbar__link ${
//                 isActive("/paint") ? "navbar__link--active" : ""
//               }`}
//             >
//               Paint
//             </Nav.Link>
//             <Nav.Link
//               onClick={() => navigate("/hardware")}
//               className={`navbar__link ${
//                 isActive("/hardware") ? "navbar__link--active" : ""
//               }`}
//             >
//               Hardware
//             </Nav.Link>
//             <Nav.Link
//               onClick={() => navigate("/plywood")}
//               className={`navbar__link ${
//                 isActive("/plywood") ? "navbar__link--active" : ""
//               }`}
//             >
//               PlyWood
//             </Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// }

// export default NavScrollExample;


import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "./Images/logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import "./Styles/Header.css";

function NavScrollExample() {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <Navbar expand="lg" className="custom-navbar" sticky="top">
      <Container fluid>
        <Navbar.Brand onClick={() => navigate("/")}>
          <img src={logo} className="navbar__logo-image" alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav className="my-2 my-lg-0" navbarScroll>
            <Nav.Link
              onClick={() => navigate("/")}
              className={`navbar__link ${isActive("/") ? "navbar__link--active" : ""}`}
            >
              Home
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/paint")}
              className={`navbar__link ${isActive("/paint") ? "navbar__link--active" : ""}`}
            >
              Paint
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/hardware")}
              className={`navbar__link ${isActive("/hardware") ? "navbar__link--active" : ""}`}
            >
              Hardware
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/plywood")}
              className={`navbar__link ${isActive("/plywood") ? "navbar__link--active" : ""}`}
            >
              PlyWood
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/gallery")}
              className={`navbar__link ${isActive("/gallery") ? "navbar__link--active" : ""}`}
            >
              Gallery
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;
