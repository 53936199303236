import React from 'react';
import './Styles/Brands.css';
import img1 from './Images/vir-mdf-logo.png';
import img2 from './Images/Sharon.png';
import img3 from './Images/product-jpeg.png';
import img4 from './Images/century.png';

const Brands = () => {
  return (
    <div className="partners_playwood">
      <div className="brand_heading">
        <h2 className="Play_wood_our_brand" data-aos="fade-up" data-aos-duration="1500">Our Partners</h2>
      </div>
      <div className="brand_logoss" data-aos="zoom-in" data-aos-duration="1500">
        <img src={img1} alt="Partner 1" className="playwood_partners__logo" />
        <img src={img2} alt="Partner 2" className="playwood_partners__logo" />
        <img src={img3} alt="Partner 3" className="playwood_partners__logo" />
        <img src={img4} alt="Partner 4" className="playwood_partners__logo" />
      </div>
    </div>
  );
};

export default Brands;
