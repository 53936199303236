import React from "react";
import "./Styles/OnTime.css";
import painter from "./Images/painter.png";
import gradBall from "./Images/Ellipse 8.png";
const OnTimeServices = () => {
  return (
    <div className="on-time-services">
      <img src={gradBall} alt="" className="gradBall" />
      <div
        className="on-time-services__header"
        data-aos="fade-right"
        data-aos-duration="1500"
      >
        <h1 className="on-time-services__title">
          On time{" "}
          <span className="on-time-services__title--highlight">services</span>
        </h1>
      </div>
      <div
        className="on-time-services__content"
        data-aos="fade-left"
        data-aos-duration="1500"
      >
        <div className="on-time-services__image">
          <img
            src={painter}
            alt="Painters"
            className="on-time-services__image-file"
          />
        </div>
        <div className="on-time-services__details">
          <h2 className="on-time-services__subtitle">
            Our painting service includes
          </h2>
          <ul className="on-time-services__list">
            <li className="on-time-services__list-item">
              <span className="on-time-services__list-icon">✔</span>
              Timely Completion: We prioritize efficiency to ensure projects are
              completed on time.
            </li>
            <li className="on-time-services__list-item">
              <span className="on-time-services__list-icon">✔</span>
              Quality Assurance: Our on-time painting service maintains
              high-quality standards without compromise.
            </li>
            <li className="on-time-services__list-item">
              <span className="on-time-services__list-icon">✔</span>
              Heritage Meets Innovation: We blend our rich legacy with modern
              techniques for superior results.
            </li>
            <li className="on-time-services__list-item">
              <span className="on-time-services__list-icon">✔</span>
              Customer Satisfaction: Our commitment to service ensures unmatched
              customer satisfaction.
            </li>
            <li className="on-time-services__list-item">
              <span className="on-time-services__list-icon">✔</span>
              Transformative Experience: We aim to turn your vision into
              reality, enhancing your space beautifully.
            </li>
          </ul>
          <p className="on-time-services__description">
            Thagappanadar Paints ensures timely project completion and quality
            service, transforming your space into a masterpiece through our
            blend of heritage and innovation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnTimeServices;
