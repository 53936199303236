import React from 'react'
 import AboutUs from './AboutUs';
 import Banner from './Banner';
 import ChooseUs from './ChooseUs';
 import Contact from './Contact';
 import Faq from './Faq';
 import Services from './Services';
 import Statistics from './Statistics';
 import Carousel_landing from './Carousel_landing'
const index = () => {
  return (
    <div>
      <Banner/>
     <Statistics/>
     <AboutUs/>
     <Services/>
     <ChooseUs/>
     <Carousel_landing/>
     <Faq/>
     <Contact/>
    </div>
  )
}

export default index
