// ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation(); // Get current path

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll to top whenever the path changes
  }, [pathname]); // Dependency on pathname

  return null; // This component does not render anything
}

export default ScrollToTop;