import React from "react";
import "./Styles/WaterProof.css";
import vita from "./Images/Vitalia Neo.png";
import Damp from "./Images/Damp proof.png";
import Repair from "./Images/Repair Polymer.png";
const WaterProof = () => {
  return (
    <div className="product-section ">
      <h2
        className="product-section__title"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        Our <span className="product-section__title--highlight">Products</span>
      </h2>
      <h3
        className="product-section__subtitle"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        Waterproofing solutions
      </h3>
      <div className="product-section__list">
        <div
          className="product-card_waterproof"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <h4 className="product-card_waterproof__name">Vitalia Neo</h4>
          <div className="product-card_waterproof__rating">
            {/* Stars can be generated dynamically */}
            ⭐⭐⭐⭐<span>⭐</span>
          </div>
          <p className="product-card_waterproof__description">
            Liquid waterproofing with plasticizing for stronger, water-resistant
            cement.
          </p>
          <div className="image-bg-colos">
            <div className="bg-colors-green">
              <img
                className="product-card_waterproof__image"
                src={vita}
                alt="Vitalia Neo"
              />
            </div>
          </div>
        </div>
        <div
          className="product-card_waterproof"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <h4 className="product-card_waterproof__name">Repair Polymer</h4>
          <div className="product-card_waterproof__rating">
            ⭐⭐⭐⭐<span>⭐</span>
          </div>
          <p className="product-card_waterproof__description">
            Economical SBR-based bonding and waterproofing for concrete repairs.
          </p>
          <div className="image-bg-colos">
            <div className="bg-colors-green">
              <img
                className="product-card_waterproof__image_Damp"
                src={Repair}
                alt="Vitalia Neo"
              />
            </div>
          </div>
        </div>
        <div
          className="product-card_waterproof"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <h4 className="product-card_waterproof__name">
            Smart Care Damp proof
          </h4>
          <div className="product-card_waterproof__rating">
            ⭐⭐⭐⭐<span>⭐</span>
          </div>
          <p className="product-card_waterproof__description">
            Seamless, durable waterproofing membrane; heat-reflective white coat
            lowers temperature by 10°C.
          </p>
          <div className="image-bg-colos">
            <div className="bg-colors-green">
              <img
                className="product-card_waterproof__image"
                src={Damp}
                alt="Vitalia Neo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaterProof;

// import React, { useEffect, useState } from "react";
// import { db } from "../firebase"; // Import the Firebase configuration
// import { collection, getDocs } from "firebase/firestore";
// import "./Styles/WaterProof.css";

// const WaterProof = () => {
//   const [products, setProducts] = useState([]);

//   // Fetch product data from Firebase
//   useEffect(() => {
//     const fetchProducts = async () => {
//       const querySnapshot = await getDocs(collection(db, "waterproffing"));
//       // Assuming collection name is 'waterproofing'
//       const productList = querySnapshot.docs.map((doc) => doc.data());
//       console.log(productList);
//       setProducts(productList);
//     };

//     fetchProducts();
//   }, []);

//   return (
//     <div className="product-section">
//       <h2
//         className="product-section__title"
//         data-aos="fade-up"
//         data-aos-duration="1500"
//       >
//         Our <span className="product-section__title--highlight">Products</span>
//       </h2>
//       <h3
//         className="product-section__subtitle"
//         data-aos="fade-up"
//         data-aos-duration="1500"
//       >
//         Waterproofing solutions
//       </h3>
//       <div className="product-section__list">
//         {products.map((product, index) => (
//           <div
//             key={index}
//             className="product-card_waterproof"
//             data-aos="fade-up"
//             data-aos-duration="1500"
//           >
//             <h4 className="product-card_waterproof__name">{product.name}</h4>
//             <div className="product-card_waterproof__rating">
//               {"⭐".repeat(product.rate)}
//               <span>{product.rate < 5 ? "⭐" : ""}</span>
//             </div>
//             <p className="product-card_waterproof__description">
//               {product.subtitle}
//             </p>
//             <div className="image-bg-colos">
//               <div
//                 className="bg-colors-green"
//                 style={{ backgroundColor: product.color }}
//               >
//                 <img
//                   className="product-card_waterproof__image"
//                   src={product.imageUrl}
//                   alt={product.name}
//                 />
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default WaterProof;
