import React from 'react'
import './Styles/Banner.css'
import logo from './Images/1.png'
const Banner = () => {
  return (
   
      <div className="welcome">
 <div className="welcome__text">
 <h1 className="welcome__title" data-aos="zoom-in" data-aos-duration="1500">Welcome to Thagappanadar Hardware!</h1>
 <p className="welcome__subtitle" data-aos="zoom-in" data-aos-duration="1500">Over a Century of Excellence in Paints, Hardware, and Plywoods</p>
 </div>
 <div className="welcome__image">
 <img src={logo} alt="House with locks" className="welcome__image-content" data-aos="zoom-in" data-aos-duration="1500"/>
 </div>
 </div>
   
  )
}

export default Banner
