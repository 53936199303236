// import React from "react";
// import "./Styles/Statistics.css";
// import btn from "./Images/ethereum-01.png.png";
// import CountUp from "react-countup";
// import { db } from "../firebase"; // Adjust this import based on your Firebase config fileimport { collection, getDocs } from "firebase/firestore";

// const Statistics = () => {
//   return (
//     <div >
//       <section className="statistics">
//         <div className="statistics__item">
//           <CountUp
//             end={400}
//             duration={2}
//             suffix="k+"
//             className="statistics__number"
//           />
//           <p className="statistics__label">Customers</p>
//         </div>
//         <div className="statistics__item">
//           <CountUp
//             end={20}
//             duration={2}
//             suffix="k+"
//             className="statistics__number"
//           />
//           <p className="statistics__label">Projects</p>
//         </div>
//         <div className="statistics__item">
//           <CountUp
//             end={20}
//             duration={2}
//             suffix="+"
//             className="statistics__number"
//           />
//           <p className="statistics__label">Year Experience</p>
//         </div>
//         <div className="statistics__item last">
//           <CountUp
//             end={100}
//             duration={2}
//             suffix="%"
//             className="statistics__number"
//           />
//           <p className="statistics__label">Quality</p>
//         </div>
//         <img src={btn} alt="" className="button-statistics" />
//       </section>
//     </div>
//   );
// };

// export default Statistics;

import React, { useState, useEffect } from "react";
import "./Styles/Statistics.css";
import btn from "./Images/ethereum-01.png.png";
import CountUp from "react-countup";
import { db } from "../firebase"; // Adjust this import based on your Firebase config file
import { collection, getDocs } from "firebase/firestore";

const Statistics = () => {
  const [counts, setCounts] = useState({
    customers: 0,
    projects: 0,
    experience: 0,
    quality: 0,
  });

  // Fetching count data from Firestore
  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "LandingCounts"));
        const countData = querySnapshot.docs.flatMap((doc) => doc.data());

        const newCounts = {
          customers: 0,
          projects: 0,
          experience: 0,
          quality: 0,
        };

        // Map the data based on the 'statistic' key
        countData.forEach((item) => {
          switch (item.statistic) {
            case "Customers":
              newCounts.customers = parseInt(item.value, 10);
              break;
            case "Projects":
              newCounts.projects = parseInt(item.value, 10);
              break;
            case "Year Experience":
              newCounts.experience = parseInt(item.value, 10);
              break;
            case "Quality":
              newCounts.quality = parseInt(item.value, 10);
              break;
            default:
              break;
          }
        });

        setCounts(newCounts);
      } catch (error) {
        console.error("Error fetching counts from Firestore: ", error);
      }
    };

    fetchCounts();
  }, []);

  return (
    <div>
      <section className="statistics" data-aos="fade-up" data-aos-duration="1500">
        <div className="statistics__item">
          <CountUp
            end={counts.customers}
            duration={2}
            suffix="k+"
            className="statistics__number"
          />
          <p className="statistics__label">Customers</p>
        </div>
        <div className="statistics__item">
          <CountUp
            end={counts.projects}
            duration={2}
            suffix="+"
            className="statistics__number"
          />
          <p className="statistics__label">Projects</p>
        </div>
        <div className="statistics__item">
          <CountUp
            end={counts.experience}
            duration={2}
            suffix="+"
            className="statistics__number"
          />
          <p className="statistics__label">Year Experience</p>
        </div>
        <div className="statistics__item last">
          <CountUp
            end={counts.quality}
            duration={2}
            suffix="%"
            className="statistics__number"
          />
          <p className="statistics__label">Quality</p>
        </div>
        <img src={btn} alt="" className="button-statistics" />
      </section>
    </div>
  );
};

export default Statistics;
