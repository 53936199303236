// import React from 'react';
// import './Styles/ContactSection.css';

// const ContactSection1 = () => {
//   return (
//  <div className="contact-section">
//       <div className="contact-section__content">
//         <h2 className="contact-section__title" data-aos="zoom-in" data-aos-duration="1500" >Let’s get in touch</h2>
//         <p className="contact-section__subtitle" data-aos="zoom-in" data-aos-duration="1500">
//           Reach out to us for product inquiries, or any assistance you need
//         </p>
//         <button className="contact-section__button">
//           Contact Us <span className="contact-section__button-icon">↗</span>
//         </button>
//       </div>
//     </div>

//   );
// };

// export default ContactSection1;

import React from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import "./Styles/ContactSection.css";

const ContactSection1 = () => {
  const navigate = useNavigate(); // Hook to get the navigate function

  const handleContactClick = () => {
    navigate("/contact-us"); // Navigate to the /contact-us route
  };

  return (
    <div className="contact-section">
      <div className="contact-section__content">
        <h2
          className="contact-section__title"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          Let’s get in touch
        </h2>
        <p
          className="contact-section__subtitle"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          Reach out to us for product inquiries, or any assistance you need
        </p>
        <button
          className="contact-section__button"
          onClick={handleContactClick}
        >
          Contact Us <span className="contact-section__button-icon">↗</span>
        </button>
      </div>
    </div>
  );
};

export default ContactSection1;
