import React from "react";
import "./Styles/Brands.css";
import logo1 from "./Images/godrej-logo.svg";
import logo2 from "./Images/brand2.jpeg";
import logo3 from "./Images/5.jpeg";

const Brands = () => {
  return (
    <div className="partners_playwood">
      <div className="brand_heading">
        <h2
          className="Play_wood_our_brand"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          Our Partners
        </h2>
      </div>
      <div className="brand_logos">
        <img
          src={logo1}
          alt="Partner 1"
          className="playwood_partners__logo"
          data-aos="zoom-in"
          data-aos-duration="1500"
        />
        <img
          src={logo2}
          alt="Partner 2"
          className="playwood_partners__logo"
          data-aos="zoom-in"
          data-aos-duration="1500"
          style={{ marginTop: '-25px' }}
        />
        <img
          src={logo3}
          alt="Partner 3"
          className="playwood_partners__logo"
          data-aos="zoom-in"
          data-aos-duration="1500"
        />
      </div>
    </div>
  );
};

export default Brands;
