import React from 'react';
import './Styles/AdviceSupport.css';
import ex1 from './Images/ex1.png'
import ex2 from './Images/ex2.png'
import ex3 from './Images/ex3.png'
import ex4 from './Images/ex4.png'
import wood4 from './Images/wood2.png'
import wood5 from './Images/wood3.png'
const AdviceSupportItem = ({ image, title, description }) => {
  return (
 
    <div className="advice-support-item" data-aos="zoom-in"data-aos-duration="1500">
      <img src={image} alt={title} className="advice-support-item__image" />
      <h3 className="advice-support-item__title">{title}</h3>
      <p className="advice-support-item__description">{description}</p>
    </div>
    
  );
};

const AdviceSupportSection = () => {
  const items = [
    { image: ex3, title: 'Personalized Service', description: 'Our team is committed to offering tailored support to meet your specific needs.' },
    { image: ex4, title: 'Expert Advice', description: 'We provide professional guidance to help you make informed decisions for your projects.' },
    { image: ex1, title: 'Support for All Projects', description: 'Whether you’re a homeowner tackling a DIY project or a contractor handling a large-scale installation, we’re here to assist.' },
    { image: ex2, title: 'Project Execution', description: 'We ensure that your project progresses smoothly and successfully from start to finish.' },
  ];

  return (
    <div className="advice-support">
       <img src={wood4} alt='wood2' className='wood4'/>
       <img src={wood5} alt='wood2' className='wood5'/>
      <h2 className="advice-support__title" data-aos="fade-up" data-aos-duration="1500">
        Expert <span className="advice-support__highlight">Advice and Support</span>
      </h2>
      <div className="advice-support__grid" data-aos="fade-up">
        {items.map((item, index) => (
          <AdviceSupportItem
            key={index}
            image={item.image}
            title={item.title}
            description={item.description}
          />
        ))}
      </div>
    </div>
  );
};

export default AdviceSupportSection;