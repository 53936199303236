import React from "react";
import "./Styles/ContactForm.css";
import contactImage from "../Common/Images/contact.svg"; // Replace with the path to your image

function ContactForm() {
  return (
    <div className="contact-form">
      <div className="contact-form__image-section">
        <img
          src={contactImage}
          alt="Contact Graphic"
          className="contact-form__image"
        />
      </div>
      <div className="contact-form__form-section">
        <h2 className="contact-form__title">Contact Form</h2>
        <form className="contact-form__form">
          <input
            type="text"
            placeholder="Name"
            className="contact-form__input"
          />
          <input
            type="email"
            placeholder="Email Id"
            className="contact-form__input"
          />
          <input
            type="tel"
            placeholder="Phone no"
            className="contact-form__input"
          />
          <textarea
            placeholder="Content"
            className="contact-form__textarea"
          ></textarea>
          <button type="submit" className="contact-form__button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
