// import React from "react";
// import "./Styles/Project.css";
// import Paint from "./Images/Rectangle 35.png";
// import Image1 from "./Images/New/1.jpeg";
// import Image2 from "./Images/New/7.jpeg";

// import Image3 from "./Images/New/15.jpeg";

// import Image4 from "./Images/New/19.jpeg";

// import Image5 from "./Images/New/9.jpeg";

// import Image6 from "./Images/New/4.jpeg";

// import Image7 from "./Images/New/10.jpeg";

// import Image8 from "./Images/New/8.jpeg";

// const Projects = () => {
//   const projectImages = [
//     { id: 1, src: Image1, alt: "Project 1" },
//     { id: 2, src: Image2, alt: "Project 2" },
//     { id: 3, src: Image3, alt: "Project 3" },
//     { id: 4, src: Image4, alt: "Project 4" },
//     { id: 5, src: Image5, alt: "Project 5" },
//     { id: 6, src: Image6, alt: "Project 6" },
//     { id: 7, src: Image7, alt: "Project 7" },
//     { id: 8, src: Image8, alt: "Project 8" },
//   ];

//   return (
//     <div className="projects">
//       <h2 className="projects__title">
//         Our{" "}
//         <span
//           className="projects__title--highlight"
//           data-aos="fade-up"
//           data-aos-duration="1500"
//         >
//           Projects
//         </span>
//       </h2>
//       <div
//         className="projects__grid"
//         data-aos="zoom-in"
//         data-aos-duration="1500"
//       >
//         {projectImages.map((image) => (
//           <div key={image.id} className="projects__item">
//             <img className="projects__image" src={image.src} alt={image.alt} />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Projects;

import React from "react";
import { Image } from "antd";
import "./Styles/Project.css";
import Paint from "./Images/Rectangle 35.png";
import Image1 from "./Images/New/90.jpeg";
import Image2 from "./Images/New/91.jpeg";
import Image3 from "./Images/New/15.jpeg";
import Image4 from "./Images/New/94.jpeg";
import Image5 from "./Images/New/95.jpeg";
import Image6 from "./Images/New/96.jpeg";
import Image7 from "./Images/New/10.jpeg";
import Image8 from "./Images/New/8.jpeg";

const Projects = () => {
  const projectImages = [
    { id: 1, src: Image1, alt: "Project 1" },
    { id: 2, src: Image2, alt: "Project 2" },
    { id: 3, src: Image3, alt: "Project 3" },
    { id: 4, src: Image4, alt: "Project 4" },
    { id: 5, src: Image5, alt: "Project 5" },
    { id: 6, src: Image6, alt: "Project 6" },
    { id: 7, src: Image7, alt: "Project 7" },
    { id: 8, src: Image8, alt: "Project 8" },
  ];

  return (
    <div className="projects">
      <h2 className="projects__title">
        Our{" "}
        <span
          className="projects__title--highlight"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          Projects
        </span>
      </h2>
      <div
        className="projects__grid"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        {projectImages.map((image) => (
          <div key={image.id} className="projects__item">
            <Image
              className="projects__image"
              src={image.src}
              alt={image.alt}
              width="100%"
              height="auto"
              preview={true} // Set to `true` if you want image preview on click
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
