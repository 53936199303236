import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./Styles/Carousel.css";
import { db } from "../firebase"; // Adjust this import based on your Firebase config fileimport { collection, getDocs } from "firebase/firestore";
// Slick carousel requires these CSS imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cImg from "./Images/Rectangle 36.png";
import Image1 from "./Images/New/1.png";
import Image2 from "./Images/New/11.jpeg";

import Image3 from "./Images/New/12.jpeg";

import Image4 from "./Images/New/13.jpeg";

import Image5 from "./Images/New/14.jpeg";
import Image6 from "./Images/New/15.jpeg";

import { collection, getDocs } from "firebase/firestore";

const GalleryCarousel = () => {
  const [galleryImages, setGalleryImages] = useState([]);

  // Fetching images from Firestore collection
  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "LandingGallery"));
        // Each document contains an 'images' array
        const images = querySnapshot.docs.flatMap((doc) => doc.data().images);
        console.log(images.length); // Confirm the structure in the console
        setGalleryImages(images);
      } catch (error) {
        console.error("Error fetching gallery images: ", error);
      }
    };

    fetchGalleryImages();
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Default for large screens
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false, // Hides the arrows if you prefer to use dots only
    responsive: [
      {
        breakpoint: 1200, // Large devices
        settings: {
          slidesToShow: 4, // Show 4 slides for screens >= 1200px
        },
      },
      {
        breakpoint: 992, // Medium devices (tablets, smaller desktops)
        settings: {
          slidesToShow: 3, // Show 3 slides for screens >= 992px
        },
      },
      {
        breakpoint: 768, // Small devices (tablets)
        settings: {
          slidesToShow: 2, // Show 2 slides for screens >= 768px
        },
      },
      {
        breakpoint: 480, // Extra small devices (mobiles)
        settings: {
          slidesToShow: 1, // Show 1 slide for screens < 480px
        },
      },
    ],
  };

  return (
    <div className="gallery-carousel">
      <h2
        className="gallery-carousel__title"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        Our{" "}
        <span className="gallery-carousel__title--highlight gradient">
          gallery
        </span>
      </h2>
      <Slider {...settings}>
        <div className="gallery-carousel__image">
          <img src={Image6} alt="Interior 1" />
        </div>
        <div className="gallery-carousel__image">
          <img src={Image2} alt="Interior 2" />
        </div>
        <div className="gallery-carousel__image">
          <img src={Image3} alt="Interior 3" />
        </div>
        <div className="gallery-carousel__image">
          <img src={Image4} alt="Interior 4" />
        </div>
        <div className="gallery-carousel__image">
          <img src={Image5} alt="Interior 5" />
        </div>
      </Slider>
      {/* {galleryImages.length > 0 ? (
        <Slider {...settings} dots={true}>
          {galleryImages.map((image, index) => (
            <div key={index} className="gallery-carousel__image">
              <img src={image.url} alt={image.title} />
            </div>
          ))}
        </Slider>
      ) : (
        <p>Loading images...</p>
      )} */}
    </div>
  );
};

export default GalleryCarousel;
