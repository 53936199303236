import React from "react";
import "./Styles/Brands.css";
import Agree from "./Images/agreement.png";
import Indigo from "./Images/Indigo-logo.png";
import Asian from "./Images/Asian paints logo.png";
import Image2 from "./Images/New/images.jpeg";
import Image3 from "./Images/New/image5.webp";
import Image4 from "./Images/New/image4.jpeg";

const Brands = () => {
  return (
    <div className="paintbrands">
      <div className="partners__image">
        <p
          className="partnerss__title"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          Our Partners
        </p>
        <img
          src={Agree}
          alt="Handshake"
          data-aos="fade-up"
          data-aos-duration="1500"
        />
      </div>

      <div className="partners__logos-container">
        <div
          className="asian_paint_conatiner_1"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <div
            className="asian-img-div"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <img src={Asian} alt="Asian Paints" className="asian_paint" />
          </div>
          <div
            className="asian-img-div"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <img src={Image2} alt="Asian Paints" className="asian_paint" />
          </div>
        </div>
        <div className="asian_paint_conatiner_1" >
          <div
            className="asian-img-div"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <img src={Image3} alt="Asian Paints" className="asian_paint" style={{marginTop:20}}/>
          </div>
          <div
            className="asian-img-div"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <img src={Image4} alt="Asian Paints" className="asian_paint" style={{marginTop:20}}/>
          </div>
          <div
            className="asian-img-div"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <img src={Indigo} alt="Asian Paints" className="asian_paint" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
