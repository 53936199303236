import React from "react";
import "./Styles/ChooseUs.css";
const ChooseUs = () => {
  return (

      <div className="why-choose-us">
        <h2 className="why-choose-us__title" data-aos="zoom-in" data-aos-duration="3000">
          Why{" "}
          <span className="why-choose-us__highlight gradient ">choose us?</span>
        </h2>
        <div className="why-choose-us__list" >
          <div className="why-choose-us__item" data-aos="zoom-in" data-aos-duration="3000">
            <div className="why-choose-us__icon why-choose-us__icon--expertise"></div>
            <h3 className="why-choose-us__heading" >Century-Long Expertise</h3>
            <p className="why-choose-us__description">
              With over 100 years in the industry, our knowledge and experience
              guarantee top-notch products and advice tailored to your needs.
            </p>
          </div>

          <div className="why-choose-us__item why-choose-us__item_2" data-aos="zoom-in" data-aos-duration="3000">
            <div className="why-choose-us__icon why-choose-us__icon--quality"></div>
            <h3 className="why-choose-us__heading">Premium Quality</h3>
            <p className="why-choose-us__description">
              We offer only the best, partnering with leading brands like Asian
              Paints, ensuring long-lasting and high-quality solutions for every
              project.
            </p>
          </div>

          <div className="why-choose-us__item" data-aos="zoom-in" data-aos-duration="3000">
            <div className="why-choose-us__icon why-choose-us__icon--service"></div>
            <h3 className="why-choose-us__heading">Customer Focused Service</h3>
            <p className="why-choose-us__description">
              We prioritize your satisfaction with personalized recommendations
              and reliable after-sales support, ensuring a seamless experience.
            </p>
          </div>

          <div className="why-choose-us__item why-choose-us__item_4" data-aos="zoom-in" data-aos-duration="3000">
            <div className="why-choose-us__icon why-choose-us__icon--solutions"></div>
            <h3 className="why-choose-us__heading">Comprehensive Solutions</h3>
            <p className="why-choose-us__description">
              From paints to hardware and plywoods, we provide a complete range
              of products, making us your go-to destination for all construction
              and renovation needs.
            </p>
          </div>
        </div>
      </div>

  );
};

export default ChooseUs;
